<template>
  <div>
    <router-link class="come-back" to="/admin/recommendation">
      <div></div>
      Вернуться назад
    </router-link>
    <h2 class="title_categories">
      {{ $route.query.type === 'edit' ? 'Редактирование рекомендации' : 'Создание рекомендации' }}</h2>
    <tricolor-line class="card_containet__tricolor"/>
    <form @submit.prevent="AddCorruption" ref="AddCorruption">
      <div v-if="$route.query.type === 'create'" class="content-sb">
        <p>Год созыва<span>*</span></p>
        <select required @change="selectConvocation" class="input blue">
          <option disabled selected>Выберите состав</option>
          <option v-for="item in $store.getters.getConvocation"
                  :key="`getConvocation${item.id}`" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div v-if="$route.query.type === 'create'" class="content-sb">
        <p>Структура ОПРФ<span>*</span></p>
        <select required ref="unit" class="input blue">
          <option disabled selected>Выберите секцию</option>
          <option v-for="item in $store.getters.getUnitData.data"
                  :key="`getUnitData${item.id}`" :value="item.id">
            {{ item.name }}
          </option>
        </select>
      </div>
      <div class="content-sb">
        <p>Название файла<span>*</span></p>
        <input required placeholder="Введите название файла" type="text" name="title" v-model="form.title" class="input blue">
      </div>
      <div class="content-sb">
        <p>Название файла (Англ)</p>
        <input placeholder="Введите файла страны (Англ)" type="text" name="title_eng" v-model="form.title_eng" class="input blue">
      </div>
      <div v-if="$route.query.type === 'edit'" class="content-sb">
        <p>Старый документ<span>*</span></p>
        <a target="_blank" :href="$store.getters.getUrlApi + form.url">Старый документ</a>
      </div>
      <div class="content-sb">
        <p>Докумет<span>*</span></p>
        <input type="file" ref="file" class="input blue">
      </div>

      <footer class="content-sb btns-create-form">
        <router-link to="/admin/recommendation" class="btn-white">Отмена</router-link>
        <input type="submit" class="btn-blue" value="Сохранить"/>
      </footer>
    </form>
  </div>
</template>

<script>
import TricolorLine from '@/components/TricolorLine'

export default {
  name: 'createCorruption',
  data () {
    return {
      form: this.$route.query.type === 'edit' ? this.$store.getters.getDetailRecommendation : {
        title: '',
        title_eng: '',
        url: ''
      }
    }
  },
  methods: {
    AddCorruption () {
      const formData = new FormData(this.$refs.AddCorruption)
      if (this.$route.query.type === 'edit') {
        if (this.$refs.file.value) {
          formData.append('is_file', 1)
          formData.append('file', this.$refs.file.files[0])
        }
        this.$store.dispatch('editRecommendation', { data: formData, id: this.form.id })
      } else {
        formData.append('is_file', 1)
        formData.append('file', this.$refs.file.files[0])
        this.$store.dispatch('createRecommendation', { data: formData, id: this.$refs.unit.value })
      }
    },
    selectConvocation (e) {
      this.$store.dispatch('setUnit', e.target.value)
    }
  },
  mounted () {
    this.$store.dispatch('setCorruptionGroup')
    if (this.$route.query.type === 'edit' && this.$store.getters.getDetailRecommendation === null) {
      this.$router.push('/admin/recommendation')
    }
  },
  components: {
    TricolorLine
  }
}
</script>

<style lang="scss">
.btns-create-form {
  border-top: 1px solid #D5D6D9;
  padding-top: 2.5rem;
  margin-top: 2.5rem;
}

.content-sb {
  margin-bottom: 1.25rem;

  span {
    color: red;
  }
}

.select, .input {
  width: 33.75rem;
}
</style>
